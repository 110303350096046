import React from "react"

import { BodyText, HighlightText, PageContainer, Section } from "../recipes";

import Layout from "../components/Layout"
import SEO from "../components/SEO"
import ContactLink from "../components/ContactLink"

import cookies from "../cookies.json";

const Page = () => (
  <Layout>
    <SEO title="Cookies" />
    <PageContainer>
      <PageContainer.Title>All the cookies</PageContainer.Title>
      <BodyText>I enjoy the old school treats and my cookies reflect my tastes. They are rustic in design. All minimum order of a dozen.</BodyText>
      {cookies.map(c => (
        <Section key={c.name}>
          <Section.Header>{c.name}</Section.Header>
          <Section.Body>{c.description}. <HighlightText>${c.price} per dozen.</HighlightText></Section.Body>
        </Section>
      ))}
      <ContactLink>Cookies please</ContactLink>
    </PageContainer>
  </Layout>
);

export default Page;
